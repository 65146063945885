<template>
  <transition type="fade">
    <wrapper-page v-if="article">
      <template slot="bg">
        <div class="article__bg" :style="style"></div>
      </template>
      <template slot="header">
        <c-icon-star></c-icon-star>
        <h2 style="color: #fff">{{ article.name }}</h2>
      </template>
      <template slot="body">
        <div class="article">
          <div class="article__sidebar">
            <router-link class="article__sidebar-link --back" :to="{name: 'articles'}">К темам</router-link>
            <div class="article__box">
              <a v-for="(link, idx) in article.texts" :key="link.id" class="article__sidebar-link"
                 :href="`#${idx+1}`">{{ link.title }}</a>
            </div>
          </div>
          <div class="article__content">
            <div class="article__box" v-for="(item, idx) in article.texts" :key="item.id" :id="idx + 1">
              <div class="article__content-item">
                <div class="article__content-item__title">{{ item.title }}</div>{{ item.text }}
              </div>
            </div>
            <h3 class="article__links">Другие волнующие темы:</h3>
            <div v-for="(link) in links" :key="link.id"
                 class="article__box article__link-item" @click="go(link.id)">
              <span class="article__link-item__title">{{ link.name }}</span>
              <span class="article__link-item__text">{{ link.description }}</span>
              <div class="article__link-item__img">
                <img :src="link.icon" :alt="link.name"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </wrapper-page>
  </transition>
</template>

<script>
import api from "@/api";
import CIconStar from "@/components/design/c-icon-star";
import {mapState} from "vuex";
import WrapperPage from "@/components/wrapper/w-page";

export default {
  name: "ArticleDetailPage",
  components: {WrapperPage, CIconStar},
  props: ['id'],
  data: function () {
    return {
      article: null,
      links: null,
      isActive: false
    }
  },
  computed: {
    ...mapState({
      gender: state => state.gender || 'b'
    }),
    style: function () {
      if (!this.article.bg) return null
      return {
        backgroundImage: `url(${this.article.bg})`
      }
    }
  },
  async mounted() {
    this.$store.commit("setBackground", "page");
    this.article = await api.getArticle(this.id)
    this.links = (await Promise.all(this.article.links.map(async i => {
      if (!i.link) return false
      const b = await api.getArticle(i.link)

      if (!b) return false
      return {
        id: b.id,
        name: b.name,
        icon: '/collages/icons/' + b.icon + '.png',
        description: b.description
      }
    }))).filter(i => i)
    document.title = 'CONVERTER. Материалы. ' + this.article.name
  },
  methods: {
    go: function (id) {
      window.location.href = '/article/' + id
    }
  }
};
</script>

<style lang="sass">
@import "src/assets/css/media"
.article
  display: flex
  max-width: 1050px
  margin: 0 auto
  &__bg
    position: fixed
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-size: cover
    z-index: 1

  &__sidebar
    width: 0

    .article__box
      display: none
      @include media-lg
        display: block

    @include media-lg
      width: 25%

    &-link
      color: #000
      font-size: 1rem
      text-decoration: none
      display: block
      margin-bottom: 15px
      font-weight: bold

      &.--back
        font-weight: bold
        color: #fff
        height: 25px
        font-size: 20px
        display: inline-block
        position: absolute
        top: 20px
        left: 0
        @include media-lg
          position: relative
          top: 0
        &:before
          content: ''
          display: inline-block
          margin-right: 10px
          background: url(/img/icon_article-arrow.svg) center center no-repeat
          width: 16px
          height: 15px
          background-size: contain

  &__links
    color: #fff
    margin-top: 70px

  &__link
    &-item
      display: flex
      flex-direction: column
      align-items: start
      border: 1px solid #ffffff
      position: relative
      padding: 20px 30px !important

      &:hover
        cursor: pointer
        border: 1px solid #00AD5E

      &__title
        color: #000
        font-weight: 800
        font-size: 1rem
        margin-bottom: 6px
        width: 85%

      &__text
        color: #979797
        font-size: 1rem
        width: 85%

      &__img
        position: absolute
        height: 80%
        max-height: 100px
        right: -20px
        top: 50%
        transform: translateY(-50%)

        img
          height: 100%

  &__content
    width: 100%
    margin-left: 0
    font-size: 1.2rem
    line-height: 140%
    padding: 40px 0
    @include media-lg
      padding: 40px 0
    @include media-lg
      width: 70%
      margin-left: 30px

    &-item
      white-space: break-spaces

      &__title
        margin-bottom: 10px
        font-weight: bold

  &__box
    background: #fff
    padding: 35px 20px
    border-radius: 15px
    margin-bottom: 30px
    @include media-md
      padding: 35px 30px

</style>
